import React, { useCallback, useEffect, useState } from 'react';
import { ArchiveModalProps } from './ArchiveModal';
import WarningModal from './WarningModal';


const DeArchiveModal = <Model extends object, Dto extends object, RelationBlueprint extends string>({
    open,
    setOpen,
    id,
    useHttp,
    resourceName,
    itemName,
    onSuccess,
    onCancel
}: ArchiveModalProps<Model, Dto, RelationBlueprint>): JSX.Element => {

    const [ internalOpen, setInternalOpen ] = useState(open);
    useEffect(() => {
        if (open) {
            setInternalOpen(true);
        }
    }, [ open ]);

    useEffect(() => {
        if (!internalOpen) {
            setTimeout(() => {
                setOpen(false);
            }, 350);
        }
    }, [ internalOpen ]);

    const handleClose = () => {
        setInternalOpen(false);
    };

    const onCancelClick = () => {
        onCancel?.(id);
        handleClose();
    };

    const handleConfirm = async() => {
        await useHttp?.update(id, { archived: false } as Dto);
        onSuccess?.(id);
        handleClose();
    };

    const handleEscape = useCallback((event: KeyboardEvent) => {
        const { key } = event;
        if (key === 'Escape') {
            onCancelClick();
        }
    }, []);


    useEffect(() => {
        window.addEventListener('keydown', handleEscape);
        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, []);

    return <WarningModal
        confirmCallback={ handleConfirm }
        cancelCallback={ onCancelClick }
        open={ internalOpen }
        setOpen={ setInternalOpen }
        title={ <>{ resourceName } de-archiveren</> }
        text={ <>
            <p>Weet u zeker dat u het volgende wilt de-archiveren?</p>
            <p className={ 'font-medium mt-2' }>{ itemName ?? resourceName }</p>
        </> }
    />;
};

export default DeArchiveModal;
