import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from '../../lib/samfe/components/Form';
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../lib/samfe/components/Form/Effects/useSchema';
import { SelectOption } from '../../lib/samfe/components/Form/Effects/useSelect';
import { optionIsSelected } from '../../lib/samfe/components/Form/Support/FieldSupport';
import Yup from '../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import useAxios from '../../lib/samfe/modules/Http/useAxios';
import { getWeekNumber, jsDateToSqlDate, sqlDateTimeToDate } from '../../lib/samfe/modules/Parse/Date';
import { ArticleModel } from '../article/ArticleTypes';
import { ArticleProducerModel } from '../article/pivot/ArticleProducer/ArticleProducerTypes';
import useArticleProducer from '../article/pivot/ArticleProducer/useArticleProducer';
import useArticle from '../article/useArticle';
import useCharge from '../charge/useCharge';
import { getProductVersionDisplayNameByProduct } from '../product/ProductFunctions';
import { ProductModel } from '../product/ProductTypes';
import useProduct from '../product/useProduct';
import usePurchaseRow from '../purchase/pivot/purchaseRow/usePurchaseRow';
import { PurchaseFields, PurchaseModel, PurchaseRelationsBluePrint, PurchaseStatus } from '../purchase/PurchaseTypes';
import usePurchase from '../purchase/usePurchase';


type ToOrderShape = {
    producer_id: number,
    product_id: number,
    week_expected: string,
    order_date: string,
    article_id: number,
    charge_id: number,
    quantity: number,
    price_per_amount: number,
    comments: string,
    remarks: string,
    status: PurchaseStatus,
    version: string,
}

/**
 * id = article id
 * @constructor
 */
const ToOrderForm: React.FC<ExtendFormModalProps<ToOrderShape>> = ({ id, open, setOpen }): JSX.Element => {

    const charge = useCharge();
    const article = useArticle();
    const articleProducer = useArticleProducer(id);
    const purchaseRow = usePurchaseRow();
    const navigate = useNavigate();
    const axios = useAxios();
    const productHook = useProduct();

    const [ articleProducers, setArticleProducers ] = useState<ArticleProducerModel[]>([]);

    const [ weekExpected, setWeekExpected ] = useState(getWeekNumber());
    const [ quantity, setQuantity ] = useState(0);
    const [ pricePerAmount, setPricePerAmount ] = useState(0);
    const [ currentArticle, setCurrentArticle ] = useState<ArticleModel>();
    const [ producerId, setProducerId ] = useState<number>();
    const [ comments, setComments ] = useState<string>();
    const [ remarks, setRemarks ] = useState<string>();
    const [ versions, setVersions ] = useState<string[]>([]);

    const [ currentProduct, setCurrentProduct ] = useState<ProductModel>();
    const [ availableProducts, setAvailableProducts ] = useState<ProductModel[]>([]);


    const getPredictedPrice = async() => {
        return await charge.getList({
            filter: `article_id=${ id }`,
            limit: 1,
            order: 'DESC',
            orderBy: 'created_at'
        }).then(charges => charges.length>0 ?charges[0].price_per_amount ?? 0 :0);
    };

    const fetchAvailableProductsForVersion = async(productNumber: string) => {
        await productHook.getList({
            filter: `number=${ productNumber }`,
            with: [ 'productVersionLogs' ],
            limit: 'all'
        }).then(setAvailableProducts);
    };

    useEffect(() => {
        if (!currentArticle?.product?.number) {
            setAvailableProducts([]);
            return;
        }
        fetchAvailableProductsForVersion(currentArticle?.product?.number).then();
    }, [ currentArticle ]);


    useEffect(() => {
        if (!currentArticle) {
            return;
        }

        const deliveryTimeInWeeks = parseInt(`${ currentArticle.delivery_time ?? 0 }`);
        const currentWeek = getWeekNumber();
        const expectedWeek = deliveryTimeInWeeks + currentWeek;
        setWeekExpected(expectedWeek - (expectedWeek>52 ?52 :0));
    }, [ currentArticle ]);


    const handleProductChange = (productId?: number) => {
        if (currentProduct && !productId) {
            return;
        }
        setCurrentProduct(availableProducts.find(product => product.id == productId));
    };

    const shape = (): Shape<ToOrderShape> => ({

        version: Yup.string()
            .label('Productversie')
            .inputType('text')
            .controlType('select')
            .options(versions.map(version => ({
                displayName: version,
                value: version
            }))),

        producer_id: Yup.number()
            .label('Leverancier')
            .required()
            .controlType('select')
            .handleValueChange((value) => setProducerId(value == -1 ?undefined :value))
            .options(articleProducers.length>0 ?articleProducers.map((ap, i) => ({
                displayName: ap.producer?.name,
                value: ap.producer_id,
                selected: producerId ?producerId === ap.producer_id :i === 0
            }) as SelectOption) :[
                {
                    displayName: 'Geen resultaten',
                    value: -1,
                    selected: true,
                    disabled: true
                }
            ]),

        week_expected: Yup.number()
            .inputType('number')
            .controlType('input')
            .label('Week verwacht')
            .description(`Huidige week: ${ getWeekNumber() }, levertijd: ${ currentArticle?.delivery_time ?? 0 } ${ currentArticle?.delivery_time === 1 ?'week' :'weken' }`)
            .defaultValue(weekExpected)
            .handleValueChange((v) => setWeekExpected(v ?? getWeekNumber()))
            .min(1)
            .max(52)
            .required(),

        product_id: Yup.number()
            .label('Productversie')
            .required()
            .disabled(!currentArticle)
            .controlType('select')
            .handleValueChange(handleProductChange)
            .options(availableProducts.length>0 ?availableProducts.map((product, i) => ({
                displayName: getProductVersionDisplayNameByProduct(product),
                value: product.id,
                selected: optionIsSelected(product.id, currentProduct?.id, i)
            }) as SelectOption) :[
                {
                    displayName: 'Geen versie beschikbaar',
                    value: undefined,
                    selected: true
                }
            ]),

        quantity: Yup.number()
            .label('Aantal besteld')
            .required()
            .inputType('number')
            .defaultValue(quantity)
            .handleValueChange(v => setQuantity(v ?? 0))
            .controlType('input'),

        price_per_amount: Yup.number()
            .label('Verwachte prijs per stuk')
            .required()
            .description('per 1000 voor bulk')
            .inputType('number')
            .min(0)
            .defaultValue(pricePerAmount)
            .handleValueChange(v => setPricePerAmount(v ?? 0))
            .controlType('input'),

        comments: Yup.string()
            .controlType('textArea')
            .defaultValue(comments ?? '')
            .handleValueChange(setComments)
            .label('Inkooporder notitie'),

        remarks: Yup.string()
            .controlType('textArea')
            .defaultValue(remarks ?? '')
            .handleValueChange(setRemarks)
            .label('Interne notitie'),

        article_id: Yup.number()
            .label('Artikel')
            .required()
            .hidden(true)
            .defaultValue(id)
            .controlType('input'),

        charge_id: Yup.number()
            .label('Charge')
            .hidden(true)
            .inputType('hidden')
            .controlType('input'),

        order_date: Yup.string()
            .required()
            .hidden(true)
            .controlType('input')
            .inputType('date')
            .defaultValue(jsDateToSqlDate()),

        status: Yup.string()
            .label('Status')
            .hidden(true)
            .required()
            .controlType('input')
            .defaultValue('open')

    });


    const { validationSchema, setShape } = useSchema<ToOrderShape>(shape());

    useEffect(() => {
        setShape(shape());
    }, [ currentArticle, articleProducers, weekExpected, versions, currentProduct, availableProducts ]);


    const initializer = async() => {
        article.getItem(id, { with: [ 'product' ] }).then(art => {
            setCurrentArticle(art);
            articleProducer.getList({
                with: [ 'producer' ]
            }).then(setArticleProducers);
        });
        getPredictedPrice().then(setPricePerAmount);

        const versionList = await axios.get<{ items: string[] }>(`/articles/${ id }/versions?limit=100`).then(
            result => result.data?.items ?? []
        );

        setVersions(versionList.reverse());
        setShape(shape());

    };

    const { formikConfig, formFields } = useForm<PurchaseModel, ToOrderShape, PurchaseRelationsBluePrint>({
        id: id,
        validationSchema: validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        skipInitialFetch: true,
        staticFormMethod: 'POST',
        useHttpHook: usePurchase,
        onSuccess: async(purchaseId) => {
            await purchaseRow.createWithParentId(purchaseId!, {
                article_id: id,
                purchase_id: purchaseId,
                price_per_amount: parseFloat(`${ pricePerAmount }`),
                status: 'open',
                product_id: currentProduct?.id!,
                quantity: quantity
            }).then(() => navigate('/purchases/' + purchaseId));
        },
        morphPayload: () => {
            const purchaseOrder: PurchaseFields = {
                order_date: sqlDateTimeToDate(),
                comments: comments ?? '',
                remarks: remarks ?? '',
                producer_id: producerId!,
                status: 'open',
                week_expected: `${ weekExpected }`
            };
            return purchaseOrder as ToOrderShape;
        }
    });

    return <FormModal
        id={ id }
        resource={ 'Inkooporder' } canSave={ producerId !== undefined }
        htmlBeforeForm={ <div>
            <p className={ 'block text-aqua font-semibold text-sm mt-3' }>Artikel</p>
            <p className={ 'text-graphite text-sm' }>{ currentArticle?.number }</p>
            <p className={ 'block text-aqua font-semibold text-sm mt-3' }>Product notitie</p>
            <p className={ 'text-graphite text-sm' }>{ currentArticle?.product?.comments }</p>
            <p className={ 'block text-aqua font-semibold text-sm mt-3' }>Artikel notitie</p>
            <p className={ 'text-graphite text-sm' }>{ currentArticle?.comments }</p>
        </div> }
        operationName={ 'toevoegen' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default ToOrderForm;