import {ProducerModel} from "../producer/ProducerTypes";
import {PurchaseRowModel} from "./pivot/purchaseRow/PurchaseRowTypes";
import {ChargeModel} from "../charge/ChargeTypes";
import {ArticleModel} from "../article/ArticleTypes";
import {SoftDeleteModel} from "../../lib/samfe/types/ModelTypes";
import {WithCount} from "../generic/ModelTypeExtensions";


export type PurchaseStatus = 'open'|'sent'|'received'|'booked'
export const PurchaseStatuses: PurchaseStatus[] = ['open', 'sent', 'received', 'booked']
export const PurchaseStatusTranslation = (status?: PurchaseStatus) => {
    switch (status){
        case "open": return 'Open';
        case "sent": return 'In bestelling'
        case "received": return 'Ontvangen'
        case "booked": return 'Ingeboekt'
        default: return 'Onbekende status'
    }
}


export type PurchaseRelationsBluePrint = (
    'purchaseRows'
    |'purchaseRows.article'
    |'purchaseRows.article.product'
    |'purchaseRows.article.charges'
    |'purchaseRows.article.purchaseRows'
    |'purchaseRows.article.charges.purchaseRow'
    |'purchaseRows.charge'
    |'purchaseRows.purchase'
    |'purchaseRows.purchase.producer'
    |'producer'
    |"producer.articles"
    |"producer.articles.charges"
    |"producer.articles.purchaseRows"
    |"producer.articles.charges.purchaseRows"
    |"producer.articles.product"
    |"producer.articleProducers.article.product"
    |'producer.articleProducers.article.package.parts'
    |"producer.articleProducers.article"
);


export type PurchaseFields = {
    producer_id: number,
    status: PurchaseStatus,
    week_expected: string,
    comments: string,
    order_date: string,
    remarks: string,
}

export type PurchaseDto = Partial<PurchaseFields>

export type PurchaseRelations = {
    purchaseRows: PurchaseRowModel[],
    producer: ProducerModel,
    article: ArticleModel,
    charge: ChargeModel
}

export type PurchaseModel = SoftDeleteModel & Partial<PurchaseRelations> & WithCount<Partial<PurchaseRelations>> & PurchaseDto
