import { FormikValues } from 'formik';
import React, { FC } from 'react';
import { useForm } from '../../lib/samfe/components/Form';
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import { CustomerDto, CustomerModel, CustomerRelationsBluePrint } from './CustomerTypes';
import useCustomer from './useCustomer';


const CustomerForm: FC<ExtendFormModalProps<CustomerDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    /**
     *
     */
    const shape = (): Shape<CustomerDto> => ({
        wc_id: Yup.number()
            .inputType('number')
            .controlType('input')
            .label('WooCommerce ID'),
        number: Yup.number()
            .inputType('number')
            .controlType('input')
            .label('Klantnummer'),
        first_name: Yup.string()
            .inputType('text')
            .controlType('input')
            .label('Voornaam')
            .required(),
        last_name: Yup.string()
            .inputType('text')
            .controlType('input')
            .label('Achternaam')
            .required(),
        company: Yup.string()
            .inputType('text')
            .controlType('input')
            .label('Bedrijf')
            .required(),
        email: Yup.string()
            .inputType('text')
            .controlType('input')
            .label('Email')
            .required(),
        phone: Yup.string()
            .inputType('text')
            .controlType('input')
            .label('Telefoonnummer')
            .required(),
        shipping_address_1: Yup.string()
            .inputType('text')
            .controlType('input')
            .label('Straat')
            .required(),
        shipping_address_2: Yup.string()
            .inputType('text')
            .controlType('input')
            .label('Adres toevoeging'),
        shipping_postcode: Yup.string()
            .inputType('text')
            .controlType('input')
            .label('Postcode')
            .required(),
        shipping_city: Yup.string()
            .inputType('text')
            .controlType('input')
            .label('Stad')
            .required(),
        shipping_country: Yup.string()
            .inputType('text')
            .controlType('input')
            .label('Land')
            .required(),
        has_article_label: Yup.boolean()
            .label('Maakt eigen label')
            .controlType('checkbox'),

        has_label: Yup.boolean()
            .label('Producten hebben geen label')
            .controlType('checkbox'),
        one_product_per_box: Yup.boolean()
            .label('1 product per doos')
            .controlType('checkbox'),
        clientlabel_on_box: Yup.boolean()
            .label('Klantlabel op de doos')
            .controlType('checkbox'),
        sticker_amount_on_box: Yup.boolean()
            .label('Aantallen sticker op doos')
            .controlType('checkbox'),
        comments: Yup.string()
            .controlType('textArea')
            .label('Klant notitie')
    });

    /**
     *
     */
    const { validationSchema, setShape } = useSchema<CustomerDto>(shape());

    const initializer = async() => {
        setShape(shape());
    };

    /**
     *
     */
    const { formikConfig, formFields } = useForm<CustomerModel, CustomerDto, CustomerRelationsBluePrint>({
        id,
        validationSchema,
        morphInitialValues: id ?(model) => {
            return {
                ...model,
                has_article_label: model ?!model.has_article_label :false
            } as FormikValues;
        } :undefined,
        morphPayload: (_, dto) => {
            return {
                ...dto,
                has_article_label: !dto.has_article_label
            };
        },
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useCustomer,
        onSuccess
    });

    return <FormModal
        id={ id }
        resource={ 'Klant' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default CustomerForm;
