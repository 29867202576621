import React, {Dispatch, FC, SetStateAction} from "react";
import {useDropzone} from "react-dropzone";
import { FileDto, FileModel } from './FileTypes';
import useCurrentUser from "../user/useCurrentUser";
import useFile from "./useFile";
import {classNames, ucFirst} from "../../lib/samfe/modules/Parse/String";
import useToaster from "../../lib/samfe/components/Toaster/useToaster";
import useLoadingScreen from "../../lib/samfe/components/LoadingScreen/useLoadingScreen";
import useId from "../../lib/samfe/modules/Router/useId";
import {ActionButton, CancelButton} from "../../lib/samfe/components/Button";
import Select from "../../lib/samfe/components/Form/Generic/Select/Select";



type Props = {
    modelName: string,
    files: FileModel[]
    setFiles: Dispatch<SetStateAction<FileModel[]>>
    fileTypes: string[],
    onSuccess?: () => void
}


const UploadField: FC<Props> = ({modelName, files, setFiles, fileTypes, onSuccess}): JSX.Element => {

    const file = useFile();
    const {setToasterProps} = useToaster()
    const {setLoadingScreenProps} = useLoadingScreen()
    const {user} = useCurrentUser();
    const id = useId();

    const { getRootProps, isDragActive } = useDropzone({

        onDrop: (acceptedFiles: File[]) => {
            if (acceptedFiles.length < 1) {
                setToasterProps({
                    type: 'error',
                    title: 'Bestandtype niet ondersteund',
                    message: 'Ondersteund: doc(x), jp(e)g, pdf, png, rtf en txt',
                    show: true
                })
                return;
            }

            setFiles([
                ...files,
                ...acceptedFiles.map(file => {
                    const nameArr = file.name.split('.');
                    const ext = nameArr.pop();
                    const name = nameArr.join('.')
                    return ({
                        name: name,
                        extension: ext,
                        user_id: user?.id,
                        org_id: 0, // @todo org id support
                        model_name: modelName,
                        model_id: id,
                        type: fileTypes.length>0 ?fileTypes[0] :undefined,
                        file: file
                    }) as FileModel;
                } )
            ])
        },

        accept: {
            'image/jpeg': ['.jpg', '.jpeg', '.png'],
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc', '.docx'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'text/plain': ['.txt'],
            'application/rtf': ['.rtf'],
        },
    });


    const cancelUpload = () => {
        setFiles([])
    }


    const submit = async () => {
        setLoadingScreenProps({
            show: true
        })

        const failedIndexes: number[] = []
        let fileIndex = 0;
        for (const fileUpload of files) {

            const formData = new FormData();
            const fileModelFields: {[k:string]: any} = fileUpload;

            Object.keys(fileUpload).forEach(k => {
                if (k === 'file') {
                    formData.append( k, new Blob([ fileModelFields[k] ]) )
                    return;
                }
                formData.append(k, fileModelFields[k]);
            });

            await file.create(formData as FileDto).catch(() => {
                failedIndexes.push(fileIndex)
            });
            fileIndex++;
        }
        setLoadingScreenProps({
            show: false
        })

        const newFiles = [...files].filter((file, index) => failedIndexes.includes(index));
        setFiles(newFiles)

        if (onSuccess) {
            onSuccess()
        }
    }


    return <div className={'py-8 px-6'}>


        <div {...getRootProps()} className={classNames(
            isDragActive ? "border-sky bg-sky bg-opacity-10 text-sky" : "border-graphite border-opacity-10",
            "mt-8 mb-8 p-4 text-center border-[0.25rem] border-dashed p-6 rounded-md"
        )}>
            <span className={"block mx-auto hover:cursor-pointer text-5xl text-sky material-icons"}>{isDragActive ? 'arrow_drop_down_circle':'cloud_upload'}</span>
            <span className={'block mx-auto text-center w-full font-medium text-lg'}>{
                isDragActive
                    ?'Drop it like its hot 🔥'
                    :<>
                        <span className={'text-sky hover:cursor-pointer'}>Upload bestanden</span>&nbsp;
                        <span className={'text-graphite'}>of sleep het in dit vak</span>
                    </>
            }</span>
            <small className={'text-graphite-hover'}>PNG, JPEG, PDF, DOCX, RTF, TXT tot 64MB</small>
        </div>


        <div className={classNames(files.length < 1 && 'hidden',"-mx-4 md:-mx-8 2xl:-mx-12 my-8 text-left")}>
            <table className={'w-full'}>
                <thead className={'h-12 border-t'}>
                    <tr>
                        <th className={'pl-4 md:pl-8 lg:pl-12 text-sm font-semibold text-gray-900'}>Bestandsnaam</th>
                        <th className={'px-2 md:px-4 lg:px-6 text-sm font-semibold text-gray-900'}>Type</th>
                        <th className={'pr-4 md:pr-8 lg:pr-12 !text-right text-sm font-semibold text-gray-900'}>Actie</th>
                    </tr>
                </thead>
                <tbody className={''}>
                    {files.map((file, i) =>
                        <tr key={i} className={classNames('py-4  border-t border-collapse h-14', files.length-1 === i && 'border-b' )}>
                            <td className={'pl-4 md:pl-8 lg:pl-12'}>
                                <span className={'block w-[17rem] truncate'}>
                                    {file.name}
                                </span>
                                </td>
                            <td className={'px-2 md:px-4 lg:px-6'}>
                                <div className={'w-[20rem] -mt-2'}>
                                    <Select label={''} name={''} className={'z-20'}
                                            onChange={(e) => {
                                                if (!e.value) {
                                                    console.error('upload field select type does not have a value!')
                                                    return
                                                }
                                                const newFiles = [...files];
                                                newFiles[i] = {...newFiles[i], type: e.value.toString()}
                                                setFiles(newFiles)
                                            }}
                                            options={fileTypes.map((type, i) => ({
                                                displayName: ucFirst(type),
                                                value: type,
                                                selected: file.type !== undefined ? file.type === type : i === 0
                                            }))}
                                    />
                                </div>
                            </td>
                            <td className={'pr-4 md:pr-8 lg:pr-12  !text-right'}>
                                <div className=" !-mt-2">
                                    <CancelButton
                                        className={'!p-0 !m-0 !w-auto !text-sm !font-normal'}
                                        style={"tertiary"}
                                        disabled={files.length < 1}
                                        onClick={() => {
                                            const newList = [...files]
                                            newList.splice(i, 1)
                                            setFiles(newList)
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
        <div className="relative w-full my-8 h-8">
            {files.length > 0 && <div className={'absolute right-0'}>
                <CancelButton
                    style={"tertiary"}
                    disabled={files.length < 1}
                    onClick={cancelUpload}
                />

                <ActionButton
                    text={'Uploaden'} icon={''}
                    disabled={files.length < 1}
                    onClick={submit}
                />
            </div>}
        </div>
    </div>
}
export default UploadField;