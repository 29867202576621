import React from 'react';


type Props = {
    message?: string
}

const WarningMessage: React.FC<Props> = ({ message }): JSX.Element => {

    return <p className="mt-2 text-sm text-orange-600">
        { message }
    </p>;
};
export default WarningMessage;
