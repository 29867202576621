import React, { useMemo } from 'react';
import {Field} from "formik"
import Label from "../Caption/Label";
import ErrorMessage from "../Caption/ErrorMessage";
import { RadioGroup } from '@headlessui/react'
import WarningMessage from '../Caption/WarningMessage';
import useSelect, {SelectOption} from "../Effects/useSelect";
import Skeleton from "../../Skeleton/Skeleton";
import classNames from "classnames";
import {requiredFieldClassname} from "../Support/FieldSupport";

type Props = {
    label: string;
    name: string;
    required?: boolean
    options: SelectOption[],
    defaultValue?: boolean
    disabled?: boolean,
    warning?: string
}

const Radio: React.FC<Props> = ({
    label,
    name,
    required,
    options,
    disabled,
    warning
}): JSX.Element => {

    const {
        selected, setSelected,
        field, meta, invalid
    } = useSelect(name, options);

    const hasError = useMemo(() => {
        return  meta && meta.touched && meta.error
    }, [ meta ]);

    const hasWarning = useMemo(() => {
        return (warning ?? '').replaceAll(' ', '') !== ''
    }, [warning]);

    return (selected ? <>

        <Field
            {...field}
            id={name}
            as={'input'}
            className={'hidden'}
            data-testid={field.name}
            required={required}
            disabled={disabled}
        />

        <RadioGroup value={selected} onChange={setSelected}>

            <RadioGroup.Label>
                <Label invalid={invalid} htmlFor={name} warning={hasWarning}>{label}{required && '*'}</Label>
            </RadioGroup.Label>

            <div className="grid grid-cols-3 gap-3 sm:grid-cols-6 mt-2">
                {options.map((option) =>
                    <RadioGroup.Option
                        key={option.value}
                        value={option}
                        disabled={option.disabled === true}
                        className={({ active, checked }) => classNames('cursor-pointer focus:outline-none flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1',
                        active && 'ring-2 ring-blue-600 ring-offset-2',
                        checked ? 'bg-blue-600 text-white hover:bg-blue-500' : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                            disabled && 'hover:cursor-not-allowed opacity-60',
                            required && requiredFieldClassname
                    )}>
                        <RadioGroup.Label as="span">{option.displayName}</RadioGroup.Label>
                    </RadioGroup.Option>
                )}
            </div>

        </RadioGroup>

        {hasError && <ErrorMessage meta={meta} /> }
        {hasWarning && !hasError && <WarningMessage message={warning} />}
    </>: <Skeleton type={"radio"} />)
}
export default Radio;
