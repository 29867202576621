import React, { FC, ReactNode } from 'react';

const IncomingGoodsGroupItem: FC<{
    id: ReactNode,
    deliveryDate: ReactNode,
    supplier: ReactNode,
    productNumber: ReactNode,
    productName: ReactNode,
    price: ReactNode,
    orderedAmount: ReactNode,
    deliveredAmount: ReactNode,
    orderDate: ReactNode,
    status: ReactNode,
    note: ReactNode,
    linkTo: string,
    edit: ReactNode,
}> = (props) => <>
    <div className={ 'grid grid-cols-12 min-w-[128rem] gap-x-8' }>
        <div className={ 'col-span-6 grid grid-cols-12' }>
            <div className={ 'col-span-2' }>
                { props.linkTo ? <a className='underline decoration-aqua text-aqua underline-offset-2 decoration-[0.03125rem]' href={ props.linkTo }>{ props.id }</a> : props.id }
            </div>
            <div className={ 'col-span-2' }>{ props.deliveryDate }</div>
            <div className={ 'col-span-3' }>
                <div className={ 'text-wrap' }>{ props.supplier }</div>
            </div>
            <div className={ 'col-span-2' }>{ props.productNumber }</div>
            <div className={ 'col-span-3' }>
                <div className={ 'text-wrap' }>{ props.productName }</div>
            </div>
        </div>
        <div className={ 'col-span-4 grid grid-cols-5' }>
            <div>{ props.price }</div>
            <div>{ props.orderedAmount }</div>
            <div>{ props.deliveredAmount }</div>
            <div>{ props.orderDate }</div>
            <div>{ props.status }</div>
        </div>
        <div className={ 'col-span-1' }>
            <div className={ 'text-wrap' }>{ props.note }</div>
        </div>
        <div className={ 'col-span-1 text-right' }>
            {props.edit}
        </div>
    </div>
</>;
export default IncomingGoodsGroupItem;


