import moment from 'moment/moment';
import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import Button from '../../../components/button';
import { dispatchHttpEvent, useHttpEvent } from '../../../events/Http.event';
import Input from '../../../lib/samfe/components/Form/Generic/Input';
import Icon from '../../../lib/samfe/components/Icon/Icon';
import { thousandSeparator, toEuroString } from '../../../lib/samfe/modules/Parse/Number';
import { classNames } from '../../../lib/samfe/modules/Parse/String';
import PurchaseRowForm from '../../purchase/pivot/purchaseRow/PurchaseRowForm';
import usePurchaseRow from '../../purchase/pivot/purchaseRow/usePurchaseRow';
import { PurchaseStatusTranslation } from '../../purchase/PurchaseTypes';
import { PurchaseRowItem } from '../IncomingGoodsTypes';
import IncomingGoodsGroupItem from './IncomingGoodsGroupItem';


const PurchaseRowGroupItem: FC<{
    purchaseRow: PurchaseRowItem,
    setHighlightChangedRowId: Dispatch<SetStateAction<number|undefined>>
}> = ({ purchaseRow, setHighlightChangedRowId }) => {


    const purchaseRowHook = usePurchaseRow(purchaseRow.purchase_id);
    const httpDispatched = useHttpEvent();

    const [ editMode, setEditMode ] = useState(false);
    const [ currentConfirmedDeliveryDate, setCurrentConfirmedDeliveryDate ] = useState(purchaseRow.confirmed_delivery_date);
    const [ currentPurchaseRow, setCurrentPurchaseRow ] = useState<PurchaseRowItem>();
    const [ openEditModal, setOpenEditModal ] = useState(false);

    useEffect(() => {
        setCurrentConfirmedDeliveryDate(purchaseRow.confirmed_delivery_date);
    }, [ purchaseRow.confirmed_delivery_date, httpDispatched ]);


    const deliveryConfirmed = useMemo(() => {
        return currentConfirmedDeliveryDate != null && currentConfirmedDeliveryDate != '';
    }, [ currentConfirmedDeliveryDate ]);

    const deliveryDate = useMemo(() => {
        if (deliveryConfirmed) {
            return currentConfirmedDeliveryDate;
        }
        return purchaseRow.expected_delivery_date;
    }, [ deliveryConfirmed, purchaseRow.expected_delivery_date, currentConfirmedDeliveryDate ]);

    const formattedDeliveryDate = useMemo(() => {
        return moment(deliveryDate).format('DD-MM-YYYY');
    }, [ deliveryDate ]);


    const handleSave = async() => {
        //@ts-ignore
        await purchaseRowHook.update(purchaseRow.purchase_row_id, { confirmed_delivery_date: currentConfirmedDeliveryDate })
            .then(() => {
                setEditMode(false);
                setHighlightChangedRowId(purchaseRow.purchase_row_id);
            })
            .finally(() => dispatchHttpEvent());
    };

    const handleCancel = () => {
        setCurrentConfirmedDeliveryDate(purchaseRow.confirmed_delivery_date);
        setEditMode(false);
    };

    return <>
        <div className={ classNames('pt-1', editMode && '-mb-2.5') }>
            <IncomingGoodsGroupItem
                id={ purchaseRow.purchase_id }
                deliveryDate={ <div>
                    { !editMode && <div>
                        <span className={ classNames(!deliveryConfirmed && 'opacity-50') }>{ formattedDeliveryDate }</span>
                        <button onClick={ () => setEditMode(true) }>
                            <Icon className={ 'text-xs ml-2' } materialIconName={ 'edit' }/>
                        </button>
                    </div> }
                    { editMode && <div className={ 'inline-flex items-center content-center' }>
                        <Input
                            label={ '' }
                            name={ 'date' }
                            type={ 'date' }
                            className={ '!w-28 !text-xs !py-1 !-mt-2.5' }
                            value={ currentConfirmedDeliveryDate ?? undefined }
                            onChange={ (v) => setCurrentConfirmedDeliveryDate(`${ v }`) }
                        />

                        <button className={ ' ml-2 -mt-2' } onClick={ handleSave }>
                            <Icon className={ 'text-xs' } materialIconName={ 'check' }/>
                        </button>
                        <button className={ 'ml-2 -mt-2' } onClick={ handleCancel }>
                            <Icon className={ 'text-xs' } materialIconName={ 'close' }/>
                        </button>
                    </div> }

                </div> }
                supplier={ purchaseRow.producer_name }
                productNumber={ purchaseRow.article_number }
                productName={ purchaseRow.product_name }
                price={ toEuroString(purchaseRow.price_per_amount) }
                orderedAmount={ thousandSeparator(purchaseRow.quantity) }
                deliveredAmount={ thousandSeparator(purchaseRow.booked_quantity ?? 0) }
                orderDate={ purchaseRow.sent_at ?moment(purchaseRow.sent_at).format('DD-MM-YYYY') :'-' }
                status={ PurchaseStatusTranslation(purchaseRow.status) }
                note={ purchaseRow.comments }
                linkTo={ `/purchases/${ purchaseRow.purchase_id }` }
                edit={ <Button
                    variation={ 'secondary' }
                    display={ 'squeezed' }
                    onClick={ () => {
                        setCurrentPurchaseRow(purchaseRow);
                        setOpenEditModal(true);
                    } }
                >
                    Aanpassen
                </Button> }
            />
        </div>

        { openEditModal && <PurchaseRowForm
            open={ openEditModal }
            setOpen={ setOpenEditModal }
            id={ currentPurchaseRow?.purchase_row_id }
            parentId={ currentPurchaseRow?.purchase_id }
            onSuccess={ () => {
                setCurrentPurchaseRow(undefined);
                dispatchHttpEvent();
            } }
        /> }
    </>;
};
export default PurchaseRowGroupItem;