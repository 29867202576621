import { SoftDeleteModel } from '../../lib/samfe/types/ModelTypes';
import { ChargeModel } from '../charge/ChargeTypes';
import { CustomerModel } from '../customer/CustomerTypes';
import { FileModel } from '../document/FileTypes';
import { WithCount } from '../generic/ModelTypeExtensions';
import { PackageModel } from '../package/PackageTypes';
import { ProducerModel } from '../producer/ProducerTypes';
import { ProductModel } from '../product/ProductTypes';
import { PurchaseRowModel } from '../purchase/pivot/purchaseRow/PurchaseRowTypes';
import { PurchaseModel } from '../purchase/PurchaseTypes';
import { RepackingModel } from '../repacking/RepackingTypes';
import { SaleRowModel } from '../sale/pivot/SaleRowTypes';
import { SaleModel } from '../sale/SaleTypes';
import { SamfeStockItem, StockArticleModel } from '../stock/StockTypes';
import { ArticleProducerModel } from './pivot/ArticleProducer/ArticleProducerTypes';


export type ArticleRelationsBluePrint = (
    'product'
    |'product.productType'
    |'product.productDisplayNumber'
    |'package'
    |'package.parts'
    |'package.packageParts.part'
    |'producers'
    |'producers.purchases.purchaseRows.charge'
    |'purchases'
    |'purchaseRows'
    |'purchaseRows.charge'
    |'purchaseRows.purchase'
    |'purchaseRows.purchase.producer'
    |'purchases.producer'
    |'sales'
    |'sales.customer'
    |'sales.saleRows'
    |'saleRows'
    |'saleRows.sale'
    |'saleRows.charge'
    |'saleRows.sale.customer'
    |'stock'
    |'customers'
    |'files'
    |'charges'
    |'charges.stock'
    |'charges.purchaseRow'
    |'charges.location'
    |'charges.purchaseRow.purchase.producer'
    |'articleProducers'
    |'articleProducers.producer'
    |'fromRepackOrders'
    |'toRepackOrders'
    |'samfeStock'
    |'totalSalesAfter2012'
    |'totalSalesAfter2022'
    );

export type ArticleUnit = 'Stuks'|'Gram'|'Milliliter'
export const ArticleUnits: ArticleUnit[] = [ 'Stuks', 'Gram', 'Milliliter' ];
export const NormalizeArticleUnit = (unit?: string): ArticleUnit => {
    switch (unit) {
        case 'Stuks':
            return 'Stuks';
        case 'Gram':
            return 'Gram';
        case 'Milliliter':
            return 'Milliliter';
        default:
            return 'Stuks';
    }
};

export type ArticleFields = {
    product_id: number,
    number: string,
    package_id: number,
    delivery_time: number,
    minimum_stock: number,
    amount: number,
    unit: ArticleUnit,
    is_bulk: boolean|1|0,
    exclude_till: string,
    delivery_margin?: number,
    comments?: string,
    active: boolean|1|0,
    exclude_to_order: boolean
}

export type ArticleDto = Partial<ArticleFields>

export type ArticleRelations = {
    articleProducers: ArticleProducerModel[],
    product: ProductModel,
    package: PackageModel,
    producers: ProducerModel[]
    purchases: PurchaseModel[]
    purchaseRows: PurchaseRowModel[]
    charges: ChargeModel[]
    customers: CustomerModel[]
    sales: SaleModel[],
    saleRows: SaleRowModel[],
    stock: StockArticleModel
    files: FileModel[],
    fromRepackOrders: RepackingModel[],
    toRepackOrders: RepackingModel[],
    samfeStock: SamfeStockItem,
    totalSalesAfter2012: number,
    totalSalesAfter2022: number
}

export type ArticleModel = SoftDeleteModel
    &Partial<ArticleRelations>
    &WithCount<Partial<ArticleRelations>>
    &ArticleDto
    &{
    product_number?: number|string
}