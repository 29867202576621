import moment from 'moment/moment';
import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import Button from '../../../components/button';
import { dispatchHttpEvent, useHttpEvent } from '../../../events/Http.event';
import Input from '../../../lib/samfe/components/Form/Generic/Input';
import Icon from '../../../lib/samfe/components/Icon/Icon';
import { thousandSeparator, toEuroString } from '../../../lib/samfe/modules/Parse/Number';
import { classNames } from '../../../lib/samfe/modules/Parse/String';
import RepackingForm from '../../repacking/RepackingForm';
import { RepackStatusTranslation } from '../../repacking/RepackingTypes';
import useRepackingPivotless from '../../repacking/useRepackingPivotless';
import { RepackItem } from '../IncomingGoodsTypes';
import IncomingGoodsGroupItem from './IncomingGoodsGroupItem';


const RepackGroupItem: FC<{
    repackOrder: RepackItem,
    setHighlightChangedRepackId: Dispatch<SetStateAction<number|undefined>>
}> = ({ repackOrder, setHighlightChangedRepackId }) => {

    const repackHook = useRepackingPivotless();
    const httpDispatched = useHttpEvent();

    const [ editMode, setEditMode ] = useState(false);
    const [ repackDate, setRepackDate ] = useState((repackOrder.repack_date ?? '').split(' ')[0]);
    const [ currentRepackOrder, setCurrentRepackOrder ] = useState<RepackItem>();
    const [ openEditModal, setOpenEditModal ] = useState(false);

    useEffect(() => {
        setRepackDate(repackOrder.repack_date);
    }, [ repackOrder.repack_date, httpDispatched ]);

    const formattedRepackDate = useMemo(() => {
        return moment(repackDate).format('DD-MM-YYYY');
    }, [ repackDate ]);


    const handleSave = async() => {
        await repackHook.update(repackOrder.id, { repack_date: repackDate })
            .then(() => {
                setEditMode(false);
                setHighlightChangedRepackId(repackOrder.id);
            })
            .finally(() => dispatchHttpEvent());
    };

    const handleCancel = () => {
        setRepackDate(repackOrder.repack_date);
        setEditMode(false);
    };

    return <>
        <div className={ classNames('pt-1', editMode && '-mb-2.5') }>
            <IncomingGoodsGroupItem
                id={ repackOrder.id }
                deliveryDate={ <div>
                    { !editMode && <div>
                        <span>{ formattedRepackDate }</span>
                        <button onClick={ () => setEditMode(true) }>
                            <Icon className={ 'text-xs ml-2' } materialIconName={ 'edit' }/>
                        </button>
                    </div> }
                    { editMode && <div className={ 'inline-flex items-center content-center' }>
                        <Input
                            label={ '' }
                            name={ 'date' }
                            type={ 'date' }
                            className={ '!w-28 !text-xs !py-1 !-mt-2.5' }
                            value={ repackDate ?? undefined }
                            onChange={ (v) => setRepackDate(`${ v }`) }
                        />
                        <button className={ ' ml-2 -mt-2' } onClick={ handleSave }>
                            <Icon className={ 'text-xs' } materialIconName={ 'check' }/>
                        </button>
                        <button className={ 'ml-2 -mt-2' } onClick={ handleCancel }>
                            <Icon className={ 'text-xs' } materialIconName={ 'close' }/>
                        </button>
                    </div> }

                </div> }
                supplier={ repackOrder.producer_name }
                productNumber={ repackOrder.target_article_number }
                productName={ repackOrder.product_name }
                price={ toEuroString(repackOrder.price_per_amount ?? 0) }
                orderedAmount={ thousandSeparator(repackOrder.amount) }
                deliveredAmount={ 0 }
                orderDate={ formattedRepackDate }
                status={ RepackStatusTranslation(repackOrder.status) }
                note={ repackOrder.comments }
                linkTo={ '' }
                edit={ <Button
                    variation={ 'secondary' }
                    display={ 'squeezed' }
                    onClick={() => {
                        setCurrentRepackOrder(repackOrder)
                        setOpenEditModal(true);
                    }}
                >
                    Aanpassen
                </Button> }
            />
        </div>

        {
            openEditModal && currentRepackOrder && <RepackingForm
                id={ currentRepackOrder?.id }
                open={ openEditModal }
                setOpen={ setOpenEditModal }
                parentId={ currentRepackOrder?.parent_charge_id }
                onSuccess={ () => {
                    setCurrentRepackOrder(undefined)
                    dispatchHttpEvent()
                } }
            />
        }
    </>
};

export default RepackGroupItem;
