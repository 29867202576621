import React from "react";
import * as Yup from 'yup';
import {setLocale, StringSchema, NumberSchema, BooleanSchema} from 'yup';
import {FormControlType} from "./FormControl";
import {SelectOption} from "./Effects/useSelect";
import {nl} from "yup-locales";
import {MinimumModel} from "../../types/ModelTypes";
import {SelectSearchConfig} from "./Field/SelectSearch/SelectSearchTypes";


/** Localization */
setLocale(nl);


/**
 * Declare custom properties for Yup in order to manage form control.
 */
declare module 'yup' {

    interface BaseSchema<T> {
        controlType(controlType: FormControlType): T;
        inputType(inputType: React.HTMLInputTypeAttribute): T;
        description(description: string): T;
        options(options: SelectOption[]): T;
        multiSelect(isMultiSelect: boolean): T;
        hidden(isHidden: boolean): T;
        className(className: string): T;
        dropDownClassName(dropDownClassName: string): T;
        disabled(isDisabled: boolean): T;
        warning(msg?: string): T;


        selectSearchConfig<Model extends MinimumModel, Dto extends Partial<Model>, RelationBlueprint extends string>(
            config:  SelectSearchConfig<Model, Dto , RelationBlueprint>
        ): T;
    }

    interface StringSchema extends BaseSchema<StringSchema> {
        defaultValue(value: string): StringSchema
        handleValueChange(callBack: (value?:string) => void): StringSchema
    }

    interface BooleanSchema extends BaseSchema<BooleanSchema> {
        defaultValue(value: boolean): BooleanSchema
        handleValueChange(callBack: (value?:boolean) => void): BooleanSchema

    }

    interface NumberSchema extends BaseSchema<NumberSchema> {
        defaultValue(value?: number): NumberSchema
        steps(stepSize: number): NumberSchema;
        handleValueChange(callBack: (value?:number) => void): NumberSchema

    }
}


/**
 * Add custom properties to spec.meta.
 *
 * @param schema
 * @param propertyName
 */
function setMeta<T>(schema: any, propertyName: string): void {
    Yup.addMethod(schema, propertyName, function(this: Yup.Schema, value: T): Yup.Schema {
        return this.test(
            propertyName,
            `Invalid value:${value} for property:${propertyName} given.`,
            () => true
        ).meta({ [`${propertyName}`]: value });
    });
}


/**
 * Global types.
 *
 * Add custom meta methods so that form fields can be created from schema.
 */
[Yup.string, Yup.number, Yup.boolean].forEach(schemaType => {
    setMeta<FormControlType>(schemaType, 'controlType')
    setMeta<React.HTMLInputTypeAttribute>(schemaType, 'inputType')
    setMeta<string|undefined>(schemaType, 'description')
    setMeta<string|undefined>(schemaType, 'warning')
    setMeta<SelectOption[]|undefined>(schemaType, 'options')
    setMeta<boolean|undefined>(schemaType, 'multiSelect')
    setMeta<boolean|undefined>(schemaType, 'hidden')
    setMeta<boolean|undefined>(schemaType, 'disabled')
    setMeta<any|undefined>(schemaType, 'useSearchOptions')
    setMeta<any|undefined>(schemaType, 'selectSearchConfig')
    setMeta<any|undefined>(schemaType, 'className')
});


/**
 * String types
 */
[Yup.string].forEach(schemaType => {
    setMeta<string|undefined>(schemaType, 'defaultValue')
    setMeta<((value:string) => void)|undefined>(schemaType, 'handleValueChange')
});


/**
 * Boolean types
 */
[Yup.boolean].forEach(schemaType => {
    setMeta<boolean|undefined>(schemaType, 'defaultValue')
    setMeta<((value:boolean) => void)|undefined>(schemaType, 'handleValueChange')
});


/**
 * Number types.
 */
[Yup.number].forEach(schemaType => {
    setMeta<number|undefined>(schemaType, 'defaultValue')
    setMeta<number|undefined>(schemaType, 'steps')
    setMeta<((value:number) => void)|undefined>(schemaType, 'handleValueChange')
});

export default Yup;