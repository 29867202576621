import moment from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Table from '../../components/table';
import { TableColumns } from '../../components/table/types/TableRow';
import { useHttpEvent } from '../../events/Http.event';
import IncomingGoodsGroup from './components/IncomingGoodsGroup';
import IncomingGoodsGroupItem from './components/IncomingGoodsGroupItem';
import { IncomingGoodsModel } from './IncomingGoodsTypes';
import 'moment/locale/nl';
import useIncomingGoods from './useIncomingGoods';


const IncomingGoodsTable: FC = () => {

    const httpHook = useIncomingGoods();
    const httpDispatched = useHttpEvent();
    const [ highlightChangedPurchaseRowId, setHighlightChangedRowId ] = useState<number>();
    const [ highlightChangedRepackId, setHighlightChangedRepackId ] = useState<number>();

    useEffect(() => {
        if (!highlightChangedPurchaseRowId) {
            return;
        }
        setTimeout(() => {
            setHighlightChangedRowId(undefined);
        }, 5000);
    }, [ highlightChangedPurchaseRowId ]);

    useEffect(() => {
        if (!highlightChangedRepackId) {
            return;
        }
        setTimeout(() => {
            setHighlightChangedRepackId(undefined);
        }, 5000);
    }, [ highlightChangedRepackId ]);


    //noinspection com.intellij.reactbuddy.ExhaustiveDepsInspection
    const reOrder = useCallback((items: IncomingGoodsModel[]): IncomingGoodsModel[] => {

        const newItems: IncomingGoodsModel[] = [];

        items.forEach(item => {

            const newItem: IncomingGoodsModel = {
                week_number: item.week_number,
                year: item.year,
                purchase_rows: [],
                repack_orders: []
            };

            newItem.purchase_rows.push(...item.purchase_rows
                .sort((a, b) => {
                    if (a.purchase_id == b.purchase_id) {
                        return 0;
                    }
                    return a.purchase_id<b.purchase_id ?-1 :1;
                })
                .sort((a, b) => {
                    let aVal = a.expected_delivery_date;
                    let bVal = b.expected_delivery_date;

                    if (!!a.confirmed_delivery_date && a.confirmed_delivery_date != '') {
                        aVal = a.confirmed_delivery_date;
                    }

                    if (!!b.confirmed_delivery_date && b.confirmed_delivery_date != '') {
                        bVal = b.confirmed_delivery_date;
                    }

                    return moment(aVal).valueOf() - moment(bVal).valueOf();
                }));

            newItem.repack_orders.push(...item.repack_orders
                .sort((a, b) => {
                    if (a.id == b.id) {
                        return 0;
                    }
                    return a.id<b.id ?-1 :1;
                })
                .sort((a, b) => {
                    let aVal = a.repack_date;
                    let bVal = b.repack_date;
                    return moment(aVal).valueOf() - moment(bVal).valueOf();
                }));

            newItems.push(newItem);
        });

        return newItems;
    }, [ httpDispatched, highlightChangedPurchaseRowId, highlightChangedRepackId ]);

    //noinspection com.intellij.reactbuddy.ExhaustiveDepsInspection
    const rows = useMemo((): TableColumns<IncomingGoodsModel, ''>[] => [
        {
            header: {
                children: <IncomingGoodsGroupItem
                    id={ 'Inkooporder' }
                    deliveryDate={ 'Datum (bevestigd)' }
                    supplier={ 'Leverancier' }
                    productNumber={ 'Artikel' }
                    productName={ 'Product' }
                    price={ 'Prijs (verwacht)' }
                    orderedAmount={ 'Aantal (besteld)' }
                    deliveredAmount={ 'Aantal (geleverd)' }
                    orderDate={ 'Besteldatum' }
                    status={ 'Status' }
                    note={ 'Aanvullende informatie' }
                    linkTo={ '' }
                    edit={ 'Actie(s)' }
                />
            },
            column: (item) => ({
                children: <IncomingGoodsGroup
                    groupData={ item }
                    highlightPurchaseRowIdState={ [ highlightChangedPurchaseRowId, setHighlightChangedRowId ] }
                    highlightRepackIdState={ [ highlightChangedRepackId, setHighlightChangedRepackId ] }
                />
            }),
            type: 'text',
            style: {
                background: 'white'
            }
        }
    ], [
        highlightChangedPurchaseRowId,
        highlightChangedRepackId
    ]);

    return (
        <Table
            id={ 'incoming-goods' }
            rows={ rows }
            postProcessData={ reOrder }
            http={ {
                hook: httpHook,
                showAll: true,
                sortDir: 'DESC',
                filterConfig: {
                    hideAllFilters: true
                }
            } }
        />
    );
};

export default IncomingGoodsTable;
