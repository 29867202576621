import moment from 'moment/moment';
import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { classNames } from '../../../lib/samfe/modules/Parse/String';
import { IncomingGoodsModel, PurchaseRowItem, RepackItem } from '../IncomingGoodsTypes';
import IncomingGoodsGroupItem from './IncomingGoodsGroupItem';
import PurchaseRowGroupItem from './PurchaseRowGroupItem';
import RepackGroupItem from './RepackGroupItem';


const IncomingGoodsGroup: FC<{
    groupData: IncomingGoodsModel,
    highlightPurchaseRowIdState: [ number|undefined, Dispatch<SetStateAction<number|undefined>> ],
    highlightRepackIdState: [ number|undefined, Dispatch<SetStateAction<number|undefined>> ]
}> = ({ groupData, highlightPurchaseRowIdState, highlightRepackIdState }) => {

    const [ highlightChangedRowId, setHighlightChangedRowId ] = useMemo(() => highlightPurchaseRowIdState, [ highlightPurchaseRowIdState ]);
    const [ highlightChangedRepackId, setHighlightChangedRepackId ] = useMemo(() => highlightRepackIdState, [ highlightRepackIdState ]);

    const title = useMemo(() => {
        const weekNumber: number = groupData.week_number;
        const year: number = groupData.year;

        const startOfWeek = moment().year(year).isoWeek(weekNumber).isoWeekday(1).locale('nl');
        const endOfWeek = moment().year(year).isoWeek(weekNumber).isoWeekday(5).locale('nl');

        const startDate: string = `${ startOfWeek.date() } ${ startOfWeek.format('MMMM') }`;
        const endDate: string = `${ endOfWeek.date() } ${ endOfWeek.format('MMMM') }`;

        return `${ startDate } t/m ${ endDate }`;
    }, [ groupData.week_number, groupData.year ]);


    type IncomingGoodsGroupItem = {
        type: 'purchaseRow',
        date: string
        data: PurchaseRowItem
    }|{
        type: 'repackOrder',
        date: string
        data: RepackItem
    }


    const items = useMemo(() => {
        const groupItems: IncomingGoodsGroupItem[] = [];

        groupItems.push(...groupData.purchase_rows.map((pr): IncomingGoodsGroupItem => ({
            type: 'purchaseRow',
            date: pr.confirmed_delivery_date != null && pr.confirmed_delivery_date != '' ?pr.confirmed_delivery_date :pr.expected_delivery_date,
            data: pr
        })));

        groupItems.push(...groupData.repack_orders.map((rp): IncomingGoodsGroupItem => ({
            type: 'repackOrder',
            date: rp.repack_date,
            data: rp
        })));

        return groupItems.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());
    }, [ groupData.purchase_rows, groupData.repack_orders ]);


    return <>
        <div className={ 'bg-neutral-100/20 shadow -ml-12 -mr-4 py-3 pl-12 pr-4 font-medium text-aqua -mt-4' }>
            <IncomingGoodsGroupItem
                id={ <>Week { groupData.week_number } { groupData.year }</> }
                deliveryDate={ title }
                supplier={ '' }
                productNumber={ '' }
                productName={ '' }
                price={ '' }
                orderedAmount={ '' }
                deliveredAmount={ '' }
                orderDate={ '' }
                status={ '' }
                note={ '' }
                linkTo={ '' }
                edit={ '' }
            />
        </div>

        <div className={ '-ml-12 -mr-4 pt-0' }>
            <div className="grid gap-y-2 divide-y">
                { items.map((item, i) =>
                    <div
                        key={ i }
                        className={ classNames(
                            'pl-12 pr-4 pt-1 transition',
                            i == 0 && items.length == 1 && 'pt-2',
                            item.type == 'purchaseRow' && highlightChangedRowId == item.data.purchase_row_id && 'bg-yellow-200/75 pb-2 -mb-2',
                            item.type == 'repackOrder' && highlightChangedRepackId == item.data.id && 'bg-yellow-200/75 pb-2 -mb-2'
                        ) }
                    >
                        { item.type == 'purchaseRow' && <PurchaseRowGroupItem
                            purchaseRow={ item.data }
                            setHighlightChangedRowId={ setHighlightChangedRowId }
                        /> }
                        { item.type == 'repackOrder' && <RepackGroupItem
                            repackOrder={ item.data }
                            setHighlightChangedRepackId={ setHighlightChangedRepackId }
                        /> }
                    </div>
                ) }
            </div>
        </div>
    </>;
};
export default IncomingGoodsGroup;
