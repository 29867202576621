import { round } from '../../../../lib/samfe/modules/Parse/Number';
import { FormatDimensionsUnsafe } from './CustomerArticleLabelTypes';
import * as pdfjsLib from 'pdfjs-dist';

function getImageDimensions(file: File): Promise<FormatDimensionsUnsafe> {
    return new Promise((resolve, reject) => {
        if (!file.type.startsWith('image/')) {
            reject(new Error('File is not an image.'));
            return;
        }

        const img = new Image();

        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height,
                ratio: round(img.width / img.height, 2)
            });
            URL.revokeObjectURL(img.src);
        };

        img.onerror = () => {
            reject(new Error('Failed to load image.'));
        };

        img.src = URL.createObjectURL(file);
    });
}

function getPdfDimensions(file: File): Promise<FormatDimensionsUnsafe> {
    return new Promise((resolve, reject) => {
        if (file.type !== 'application/pdf') {
            reject(new Error('File is not a PDF.'));
            return;
        }

        const reader = new FileReader();
        reader.onload = function() {
            const arrayBuffer = reader.result as ArrayBuffer;
            const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
            loadingTask.promise.then((pdf) => {
                pdf.getPage(1).then((page) => {
                    const viewport = page.getViewport({ scale: 1, rotation: page.rotate });
                    resolve({
                        width: viewport.width,
                        height: viewport.height,
                        ratio: round(viewport.width / viewport.height, 2),
                    });
                }).catch((error) => {
                    reject(new Error('Failed to get page from PDF.'));
                });
            }).catch((error) => {
                reject(new Error('Failed to load PDF.'));
            });
        };

        reader.onerror = function() {
            reject(new Error('Failed to read file.'));
        };

        reader.readAsArrayBuffer(file);
    });
}

export function getFileDimensions(file: File): Promise<FormatDimensionsUnsafe> {
    if (file.type.startsWith('image/')) {
        return getImageDimensions(file);
    } else if (file.type === 'application/pdf') {
        return getPdfDimensions(file);
    } else {
        return Promise.reject(new Error(`Unsupported file type: ${file.type}`));
    }
}
