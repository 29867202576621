import { Field, useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { classNames } from '../../../../modules/Parse/String';
import Skeleton from '../../../Skeleton/Skeleton';
import ErrorMessage from '../../Caption/ErrorMessage';
import Label from '../../Caption/Label';
import WarningMessage from '../../Caption/WarningMessage';
import useChecked from '../../Effects/useChecked';
import useFormikField from '../../Effects/useFormikField';
import { requiredFieldClassname } from '../../Support/FieldSupport';


type Props = Omit<JSX.IntrinsicElements['input'], 'name'|'type'|'value'|'defaultValue'>&{
    label: string,
    description?: string
    name: string;
    defaultValue?: boolean
    handleValueChange?: (value: string|boolean|number) => void,
    warning?: string
}

const Checkbox: React.FC<Props> = ({
    label,
    name,
    description,
    required,
    disabled,
    defaultValue,
    handleValueChange,
    warning,
    ...props
}): JSX.Element => {

    const isChecked = (b: boolean|undefined|null|1|0) => b == true || b == 1;

    const { field, meta, invalid } = useFormikField(name);
    const [ checked, setChecked ] = useChecked(name, isChecked(field.value));
    const formikCtx = useFormikContext();
    useEffect(() => {
        formikCtx?.setFieldValue(name, isChecked(checked));
    }, [ checked ]);

    const hasError = useMemo(() => {
        return  meta && meta.touched && meta.error
    }, [ meta ]);

    const hasWarning = useMemo(() => {
        return (warning ?? '').replaceAll(' ', '') !== ''
    }, [warning]);


    return checked !== undefined ?<>
        <div className="mt-2">
            <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                    <Field
                        { ...props }
                        { ...field }
                        data-testid={ field.name }
                        required={ required }
                        value={ isChecked(field.value) }
                        onClick={ () => setChecked(!checked) }
                        onChange={ () => handleValueChange?.(!checked) }
                        checked={ isChecked(field.value) }
                        id={ name }
                        disabled={ disabled }
                        type="checkbox"
                        className={ classNames(
                            'h-4 w-4 rounded',
                            !hasError && !hasWarning && 'border-gray-300 text-blue-600 focus:ring-blue-600',
                            hasError && 'border-red-700 text-red-700 focus:ring-red-700',
                            hasWarning && !hasError && 'border-orange-600 text-orange-600 focus:ring-orange-600',
                            disabled && 'hover:cursor-not-allowed opacity-60',
                            required && requiredFieldClassname
                        ) }
                    />
                </div>

                <div className="ml-3 text-sm leading-6">
                    <Label invalid={ invalid } warning={hasWarning} htmlFor={ name }>
                        { label }{ required && '*' }
                    </Label>
                    { description && <span className={
                        classNames("text-gray-500 font-normal",
                            hasError && "!text-red-700",
                            hasWarning && !hasError && '!text-orange-600'
                        )
                    }>
                        &nbsp;<span className="sr-only">{ label }</span>&nbsp;{ description }
                    </span> }
                </div>

            </div>

            {hasError && <ErrorMessage meta={meta} /> }
            {hasWarning && !hasError && <WarningMessage message={warning} />}
        </div>
    </> :<Skeleton type={ 'checkbox' }/>;
};
export default Checkbox;
