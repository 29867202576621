/* eslint jsx-a11y/no-autofocus: 0 */

import { FormikValues } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { AnyObject, Maybe } from 'yup';
import { useAxiosContext } from '../../../modules/Http/useAxios';
import { ExtendModel, RequestParams, UseHttpHook } from '../../../modules/Http/useHttp';
import FormControl, { FormControlProps } from '../FormControl';
import { FormikConfig, FormProps } from '../index';
import { getDefaultEmptyValue, isEmpty, SupportedPayloadType, SupportedPayloadValue } from '../Support/FieldSupport';
import Yup from '../Yup';


type Props<Model, Dto, RelationBlueprint extends string> = Omit<FormikConfig<Dto>, 'validateOnChange'|'enableReinitialize'|'initialTouched'|'onSubmit'|'initialValues'|'isSaved'|'setIsSaved'>&{
    id?: number
    parentId?: number,
    parentKey?: keyof Model,
    useHttpHook: UseHttpHook<Model, Dto, RelationBlueprint>,
    //noinspection Eslint
    onSuccess?: (id?: number) => void,
    //noinspection Eslint
    morphPayload?: (values: FormikValues, currentDto: Dto, initialValues?: FormikValues) => Dto,
    skipInitialFetch?: boolean
    staticFormMethod?: 'PUT'|'POST',
    //noinspection Eslint
    morphInitialValues?: (model?: Model) => FormikValues,
    relations?: RelationBlueprint[],
    initializer: () => Promise<void>|void,
}


/**
 *
 * @param validationSchema
 * @param onSuccess
 * @param initialized
 * @param id
 * @param parentId
 * @param parentKey
 * @param useHttpHook
 * @param morphPayload
 * @param skipInitialFetch
 * @param staticFormMethod
 * @param morphInitialValues
 * @param relations
 */
/** */
const useForm = <Model extends ExtendModel, Dto extends object, RelationBlueprint extends string>({
    validationSchema,
    onSuccess,
    initialized,
    id,
    parentId,
    parentKey,
    useHttpHook,
    morphPayload,
    skipInitialFetch,
    staticFormMethod,
    morphInitialValues,
    relations = [],
    initializer
}: Props<Model, Dto, RelationBlueprint>): FormProps<Dto> => {

    const [ internalId, setInternalId ] = useState(id);
    const httpHook = useHttpHook(parentId);
    const [ initialValues, setInitialValues ] = useState<FormikValues|undefined>(undefined);
    const [ isSaved, setIsSaved ] = useState(false);
    const { isTest } = useAxiosContext();


    useEffect(() => {
        if (id) {
            setInternalId(id);
        }
        if (id && skipInitialFetch !== true) {
            httpHook.getItem(id, { with: relations } as RequestParams<Partial<Model>, RelationBlueprint>).then(res => {
                const initialValues = morphInitialValues ?morphInitialValues(res) :res;
                setInitialValues(initialValues);
            });
        }
    }, []);

    useEffect(() => {
        if (isSaved) {
            setIsSaved(false);
        }
    }, [ isSaved ]);


    const yupFields: { [key: string]: Yup.ObjectSchema<Maybe<AnyObject>> } = useMemo(() => Object
        .keys(validationSchema.fields)
        .reduce((yupFields, k) => ({
            ...yupFields,
            [k]: (validationSchema.fields as Yup.AnyObject)[k]
        }), {} as { [key: string]: Yup.ObjectSchema<Maybe<AnyObject>> }), [ validationSchema.fields ]);


    /**
     *
     * @param field
     */
    const fieldIsRequired = (field: Yup.ObjectSchema<Maybe<AnyObject>, AnyObject, any, ''>) => {
        let requiredProperty: boolean = field.tests.find(test => test.OPTIONS?.name === 'required') !== undefined;
        // Get value for number or boolean.
        if (field.type === 'number' || field.type === 'boolean') {
            const parsedField = field as { [k: string]: any };
            // @info optionality has no type hinting
            //noinspection JSUnresolvedReference
            requiredProperty = parsedField?.internalTests?.optionality !== undefined;
        }
        // @feature hidden support for non-required fields -> field.spec.meta['hidden']
        if (!isTest && (field.spec.meta['disabled'])) {
            return false;
        }
        return requiredProperty;
    };

    const fields: FormControlProps<Model, Dto, RelationBlueprint>[] = useMemo(() => {
        return Object.keys(yupFields).map(k => {
            const field = yupFields[k].spec;
            const fields: FormControlProps<Model, Dto, RelationBlueprint> = {
                name: k,
                label: field.label ?? '',
                controlType: field.meta['controlType'],
                description: field.meta['description'],
                warning: field.meta['warning'],
                type: field.meta['inputType'],
                options: field.meta['options'],
                multiSelect: field.meta['multiSelect'],
                steps: field.meta['steps'],
                disabled: !isTest ?field.meta['disabled'] :false,
                // @feature TPGA-1473
                //disabled: false,
                hidden: !isTest ?field.meta['hidden'] :false,
                required: fieldIsRequired(yupFields[k]),
                selectSearchConfig: field.meta['selectSearchConfig'],
                defaultValue: initialValues ?initialValues[k] :field.meta['defaultValue'],
                handleValueChange: field.meta['handleValueChange'],
                className: field.meta['className']
            };

            /**
             * Access min and max values.
             */
            const fieldDescription = validationSchema.describe().fields[k];
            if (validationSchema.describe().fields[k].type === 'number') {
                if ('tests' in fieldDescription) {
                    const numberControls = [ 'min', 'max' ];
                    fieldDescription.tests.forEach((test) => {
                        if (test.name !== undefined && test.params !== undefined && Object.keys(test.params).length>0) {
                            if (numberControls.includes(test.name)) {
                                fields[test.name as 'min'|'max'] = test.params[test.name] as number;
                            }
                        }
                    });
                }
            }
            return fields;
        }).filter((field) => !field.hidden ?? !id);
    }, [ isTest, initialValues, id, yupFields ]);


    const initialFormikValues: FormikValues = useMemo(() => Object.keys(yupFields).reduce((formikValues, k) => ({
        ...formikValues,
        [k]: yupFields[k].spec?.meta['defaultValue'] ?? (initialValues ?(initialValues[k] ?? '') :(initialized ?'' :undefined)) // Default value for Formik, when no default is specified it is going to crash.
    }), {} as FormikValues), [ yupFields, initialValues, initialized ]);


    const handleRes = (res: Model|{ id: number }|undefined) => {
        if (res !== undefined) {
            setInternalId(res.id);
            setIsSaved(true);
        }
    };


    /**
     *
     * @param values
     */
    const handleCreate = async(values: FormikValues) => {
        // If empty -> remove property
        let payload: { [k: string]: string|number|boolean } = Object.keys(values)
            .filter(k => !isEmpty(values[k]))
            .reduce((prev, k) => ({
                ...prev,
                [k]: values[k]
            }), {});

        if (parentKey && parentId) {
            payload[parentKey as string] = parentId;
        }

        if (morphPayload) {
            payload = morphPayload(values, payload as Dto) as {};
        }

        await httpHook.create(payload as Dto).then(handleRes);
    };


    /**
     *
     * @param id
     * @param formikValues
     */
    const handleUpdate = async(id: number|undefined, formikValues: FormikValues) => {
        const values = { ...formikValues } as { [k: string]: SupportedPayloadType };
        const initialValues = initialFormikValues;
        let payload = Object.keys(values)

            // If empty and not changed -> remove property
            .filter(k => Object.prototype.hasOwnProperty.call(initialValues, k) && !isEmpty(values[k]))

            // If not changed -> remove property
            .filter(k => values[k] !== initialValues[k])

            .reduce((prev, k) => ({
                ...prev,
                // IF empty -> (IF string -> '' ELSE -> null)
                [k]: !isEmpty(values[k]) ?values[k] :getDefaultEmptyValue(typeof (values[k] as SupportedPayloadType) as SupportedPayloadValue)
            }), {}) as Dto;

        if (morphPayload) {
            payload = morphPayload(values, payload, initialValues);
        }

        await httpHook.update(id, payload).then(handleRes);
    };


    /**
     *
     * @param values
     */
    const onSubmit = async(values: FormikValues) => {
        const shouldPut = staticFormMethod !== undefined ?(staticFormMethod === 'PUT') :(id !== undefined);
        return shouldPut ?handleUpdate(id, values) :handleCreate(values);
    };


    const formikConfig: FormikConfig<Dto> = useMemo(() => ({
        id: internalId,
        initializer,
        initialValues: initialFormikValues,
        onSubmit,
        onSuccess,
        validationSchema,
        validateOnChange: true,
        enableReinitialize: true,
        isSaved,
        setIsSaved
    }), [ internalId, initializer, onSuccess, validationSchema, isSaved, initialFormikValues ]);

    const formFields = useMemo(() => fields.map(({
        controlType,
        name,
        label,
        description,
        type,
        options,
        multiSelect,
        min,
        max,
        steps,
        hidden,
        defaultValue,
        required,
        disabled,
        handleValueChange,
        selectSearchConfig,
        className,
        warning
    }, i) => <FormControl
        key={ i }
        autoFocus={ i === 0 }
        defaultValue={ defaultValue }
        controlType={ controlType }
        label={ label }
        name={ name }
        hidden={ hidden }
        required={ required }
        disabled={ disabled }
        description={ description }
        handleValueChange={ handleValueChange }
        className={ className }
        warning={warning}
        { ...(controlType === 'input' && { type, min: min, max: max, step: steps }) }
        { ...([ 'select', 'radio' ].includes(controlType) && { options }) }
        { ...([ 'select' ].includes(controlType) && { multiSelect }) }
        { ...(controlType === 'selectSearch' && { selectSearchConfig }) }
    />), [ fields ]);


    return ({ formikConfig, formFields });
};

export default useForm;
