import React, { FC } from 'react';
import IndexPage from '../../templates/pages/IndexPage';
import CustomerArticleLabelTable from '../customer/pivot/CustomerArticleLabel/CustomerArticleLabelTable';


const DesignerCustomerLabelTable = () => <CustomerArticleLabelTable withoutFiles={ true }/>;

const DesignerTaskIndex: FC = (): JSX.Element => <IndexPage
    title={ 'Designer taken' }
    Table={ DesignerCustomerLabelTable }
/>;


export default DesignerTaskIndex;
