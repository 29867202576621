import React, { FC, useEffect, useState } from 'react';
import { useForm } from '../../lib/samfe/components/Form';
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import useAsyncMemo from '../../lib/samfe/hooks/useAsyncMemo';
import useProduct from '../product/useProduct';
import { LabelVersionDto, LabelVersionModel, LabelVersionRelationsBluePrint } from './LabelVersionTypes';
import useLabelVersion from './useLabelVersion';


const impactToText = (impact: string): string => {
    switch (impact) {
        case 'medium':
            return 'Gemiddelde aanpassing';
        case 'high':
            return 'Grote aanpassing';
        case 'low':
        default:
            return 'Kleine aanpassing';
    }
};


/**
 * @type {number | undefined} id version ID
 * @type {number | undefined} parentId product ID
 */
const LabelVersionForm: FC<ExtendFormModalProps<LabelVersionDto>> = ({
    id,
    parentId,
    open,
    setOpen,
    onSuccess
}): JSX.Element => {

    const product = useProduct();
    const labelVersion = useLabelVersion();

    const [ currentVersion, setCurrentVersion ] = useState<LabelVersionModel>();

    const productNumber = useAsyncMemo(async () => {
        return await product.getItem(parentId, {select: ['number']}).then(p => p?.number)
    }, [id])


    /**
     *
     */
    const shape = (): Shape<LabelVersionDto> => ({

        impact: Yup.string()
            .required()
            .disabled(id !== undefined)
            .label('Grootte van verandering')
            .controlType('select')
            .options([
                //'low', // 0 + x.x1
                'medium' // 0 + x.10
                //'high' // 1 + x.00
            ].map((impact) => ({
                displayName: impactToText(impact),
                value: impact,
                selected: impact === (currentVersion?.impact ?? 'medium')
            }))),

        comments: Yup.string()
            .required()
            .label('Notitie')
            .controlType('textArea')
            .defaultValue(currentVersion?.comments ?? '')
            .inputType('text'),

        product_number: Yup.string().defaultValue(productNumber ?? '').hidden(true)
    });


    /**
     *
     */
    const { validationSchema, setShape } = useSchema<LabelVersionDto>(shape());

    const initializer = async() => {
            await labelVersion.getItem(id).then(v => {
                setCurrentVersion(v);
            });
    };

    useEffect(() => {
        setShape(shape());
    }, [ productNumber, currentVersion ]);


    /**
     *
     */
    const { formikConfig, formFields } = useForm<LabelVersionModel, LabelVersionDto, LabelVersionRelationsBluePrint>({
        id,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useLabelVersion,
        morphPayload: (_, dto) => {
            return {
                ...dto,
                product_number: productNumber!
            };
        },
        onSuccess: onSuccess
    });


    return <FormModal
        id={ id }
        resource={ 'Labelversie' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default LabelVersionForm;
