import React, { useEffect, useMemo } from 'react';
import { Switch as HeadlessUiSwitch } from '@headlessui/react'
import WarningMessage from '../Caption/WarningMessage';
import useFormikField from "../Effects/useFormikField";
import {Field, useFormikContext} from "formik";
import useChecked from "../Effects/useChecked";
import Label from "../Caption/Label";
import ErrorMessage from "../Caption/ErrorMessage";
import {classNames} from "../../../modules/Parse/String";
import Skeleton from "../../Skeleton/Skeleton";
import {requiredFieldClassname} from "../Support/FieldSupport";

type Props = Omit<JSX.IntrinsicElements['input'], 'name'|'type'|'value'|'defaultValue'> & {
    label: string,
    description?: string
    name: string;
    defaultValue?: boolean
    warning?: string
}


const Switch: React.FC<Props> = ({
    label,
    name,
    description,
    required,
    disabled,
    warning
}): JSX.Element => {

    const {field, meta, invalid} = useFormikField(name)
    const [checked, setChecked] = useChecked(name, field.value)
    const formikCtx = useFormikContext();
    useEffect(() => {
        formikCtx?.setFieldValue(name, checked)
    }, [checked]);

    const hasError = useMemo(() => {
        return  meta && meta.touched && meta.error
    }, [ meta ]);

    const hasWarning = useMemo(() => {
        return (warning ?? '').replaceAll(' ', '') !== ''
    }, [warning]);

    return checked !== undefined ? <>
        <Field
            {...field}
            value={field.value === true || field.value === 1}
            checked={field.checked}
            id={name}
            type="checkbox"
            className={'hidden'}
            data-testid={field.name}
            required={required}
            disabled={disabled}
        />

        <HeadlessUiSwitch.Group onClick={() => setChecked(!checked)} as="div" className="flex items-center">

            <HeadlessUiSwitch
                value={field.value}
                checked={field.checked === true}
                onChange={() => {}}
                onClick={() => setChecked(!checked)}
                id={name}
                disabled={disabled}
                className={classNames(
                    checked ? 'bg-sky' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky focus:ring-offset-2',
                    required && requiredFieldClassname,
                    disabled && 'hover:cursor-not-allowed opacity-60',
                )}
            >
                <span className="sr-only">Switch</span>
                <span className={classNames(checked ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out')}>
                    <span className={classNames(checked ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity')} aria-hidden="true">
                        <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12"><path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/></svg>
                    </span>
                    <span className={classNames(checked ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity')} aria-hidden="true">
                        <svg className="h-3 w-3 text-sky" fill="currentColor" viewBox="0 0 12 12"><path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" /></svg>
                    </span>
                </span>
            </HeadlessUiSwitch>

            <HeadlessUiSwitch.Label as="span" className="ml-3 text-sm">
                <Label invalid={invalid} htmlFor={name} warning={hasWarning}>
                    {label}{required && '*'}
                    {description && <span className={classNames(
                        "text-gray-500 font-normal text-sm",
                        hasError && '!text-red-700',
                        hasWarning && !hasError && '!text-orange-600'
                    )}>{description}</span>}
                </Label>
            </HeadlessUiSwitch.Label>

        </HeadlessUiSwitch.Group>
        {hasError && <ErrorMessage meta={meta} /> }
        {hasWarning && !hasError && <WarningMessage message={warning} />}
    </>: <Skeleton type={'switch'} />
}
export default Switch;
