import React, { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import ExampleGraph from '../../lib/samfe/components/Graph/ExampleGraph';
import Stats, { Stat } from '../../lib/samfe/components/Stats';
import { datetimeToLocale } from '../../lib/samfe/modules/Parse/Date';
import ShowPage from '../../templates/pages/ShowPage';
import ChargeAssessmentTable from '../assessment/tables/ChargeAssessmentTable';
import { AssessmentStatus } from '../assessment/types/AssessmentTypes';
import PdfFile from '../document/PdfFile';
import ProfitLossForm from '../profitLoss/ProfitLossForm';
import ProfitLossTable from '../profitLoss/ProfitLossTable';
import ChargeForm from './ChargeForm';
import { batchCodeLabel, expirationDateLabel } from './ChargeFunctions';
import { ChargeModel, ChargeStatusDisplayName } from './ChargeTypes';
import ChargeCustomerTable from './pivot/ChargeCustomer/ChargeCustomerTable';
import ChargeLocationCreateForm from './pivot/ChargeLocations/ChargeLocationCreateForm';
import ChargeLocationTable from './pivot/ChargeLocations/ChargeLocationTable';
import ChargeSaleRowTable from './pivot/ChargeSaleRow/ChargeSaleRowTable';
import ChargePalletStickerTab from './pivot/ChargeSticker/ChargePalletStickerTab';
import ChargeSmallStickerTab from './pivot/ChargeSticker/ChargeSmallStickerTab';
import ChargeStickerTab from './pivot/ChargeSticker/ChargeStickerTab';
import useCharge from './useCharge';


const ChargeShow: FC = (): JSX.Element => {

    const [ currentCharge, setCurrentCharge ] = useState<ChargeModel|undefined>(undefined);


    const batchCode = useMemo(() => {
        return currentCharge?.batchcode ?? 'Onbekend';
    }, [ currentCharge?.batchcode ]);


    const title = useMemo(() => {
        return <>
            <Link className={ 'underline underline-offset-4 decoration-aqua' } to={ `/products/${ currentCharge?.article?.product_id }` }>
                { currentCharge?.product?.name }
            </Link>
            &nbsp;→&nbsp;
            <Link className={ 'underline underline-offset-4 decoration-aqua' } to={ `/articles/${ currentCharge?.article_id }` }>
                { currentCharge?.article?.number }
            </Link>
            &nbsp;→&nbsp;
            { batchCodeLabel(currentCharge) }
        </>;
    }, [ currentCharge?.product?.name, currentCharge?.article_id, currentCharge?.article?.number, currentCharge ]);


    const assessmentsWithAction = useMemo(() => {
        return (currentCharge?.assessments ?? []).filter(ass => ([
                'to_test',
                'test_requested',
                'not_approved'
            ] as AssessmentStatus[]).includes(ass.assessment_status ?? 'not_applicable')
        );
    }, [ currentCharge?.assessments ]);


    const chargeOriginStat: Stat = useMemo(() => {
        const chargeOrigin: Stat = {
            name: 'Onbekende bron',
            value: '-'
        };

        if (currentCharge?.parent_id) {
            chargeOrigin.name = 'Bron charge';
            chargeOrigin.value = <Link className={ 'underline' } to={ `/charges/${ currentCharge?.parent_id }` }>
                { batchCodeLabel(currentCharge?.parent) }
            </Link>;

        } else if (currentCharge?.purchaseRow) {
            chargeOrigin.name = 'Leverancier';
            chargeOrigin.value = <Link className={ 'underline' } to={ `/producers/${ currentCharge?.producer?.id }` }>
                { currentCharge?.purchaseRow.purchase?.producer?.name }
            </Link>;
        }
        return chargeOrigin;
    }, [ currentCharge?.parent, currentCharge?.purchaseRow, currentCharge?.producer?.id, currentCharge?.producer?.name ]);


    const overviewTabContent = useMemo(() => {
        return <>
        <div className={'grid grid-cols-3 gap-4'}>
            <Stats stats={ [
              {
                name: 'THT',
                value: expirationDateLabel(currentCharge?.expiration_date)
                },
                {
                  name: 'Productversie',
                  value: '@TODO'
                },
                {
                    name: 'Chargeversie',
                    value: currentCharge?.product?.version
                },
                {
                  name: 'Voorraad (Ingeboekt)',
                  value: currentCharge?.stock?.ordered?.toLocaleString('nl-NL')
                },
                {
                  name: 'Voorraad (Magazijn)',
                  value: currentCharge?.stock?.rack?.toLocaleString('nl-NL')
                },
                {
                  name: 'Voorraad (Beschikbaar)',
                  value: currentCharge?.stock?.usable_stock?.toLocaleString('nl-NL')
                },
                {
                    name: 'Artikel',
                    value: <Link className={ 'underline' } to={ `/articles/${ currentCharge?.article_id }` }>
                        { currentCharge?.article?.number }
                    </Link>
                },
                {
                    name: 'Status',
                    value: ChargeStatusDisplayName(currentCharge?.status)
                },
                {
                    name: 'Ingeboekt door',
                    value: currentCharge?.purchaseRow?.performed_by ?? '-'
                }
            ] }/>
            <Stats stats={ [
                {
                    name: 'Inkooporder',
                    value: <Link className={ 'underline' } to={ `/purchases/${ currentCharge?.purchaseRow?.purchase_id }` }>
                        { currentCharge?.purchaseRow?.purchase_id }
                    </Link>
                },
                {
                    name: 'Leverancier',
                    value: currentCharge?.purchaseRow?.purchase?.producer?.name ? currentCharge?.purchaseRow?.purchase?.producer?.name : 'Zwik'
                },
                {
                    name: 'Productietaak',
                    value: currentCharge?.packaging_slip
                },
                {
                    name: 'Inboekdatum',
                    value: datetimeToLocale(currentCharge?.received_date)
                },
            ] }/>
            </div>
            { currentCharge?.comments }
            <ExampleGraph/>
        </>;
    }, [ chargeOriginStat, currentCharge?.product?.version, currentCharge?.article_id, currentCharge?.article?.number, currentCharge?.stock?.rack, currentCharge?.status, currentCharge?.purchaseRow?.purchase_id, currentCharge?.packaging_slip, currentCharge?.received_date, currentCharge?.expiration_date ]);


    return (
        <ShowPage
            hideArchive={ true }
            FormModal={ ChargeForm }
            title={ title }
            breadcrumb={ batchCode }
            setCurrentModel={ setCurrentCharge }
            httpHook={ useCharge }
            relations={ [
                'article',
                'product',
                'stock',
                'files',
                'parent',
                'purchaseRow.purchase.producer',
                'assessments.productRisk.risk'
            ] }

            formActions={ [
                {
                    name: 'Voorraad muteren',
                    tabId: 'profit-loss',
                    icon: 'add',
                    form: ProfitLossForm
                },
                {
                    name: 'Locaties',
                    tabId: 'locations',
                    icon: 'add',
                    form: ChargeLocationCreateForm
                }
            ] }
						uploadsExtraColName={ 'Risico' }
						uploadsExtraColRef={ 'charges' }
            upload={ {
                modelName: 'ChargeModel',
                files: currentCharge?.files,
                fileTypes: [
                    'Factuur',
                    'Grondstof certificaat',
                    'Intakeformulier',
                    'Uitvul formulier',
                    'Pakbon',
                    'Referentie afbeelding',
                    'Overig',
                ],
            } }

            tabs={ !currentCharge ?[] :[
                {
                    name: 'Overzicht',
                    id: 'overview',
                    element: overviewTabContent
                },
                {
                    name: 'Locaties',
                    id: 'locations',
                    element: <>
                        { currentCharge.id != undefined && <ChargeLocationTable parentId={ currentCharge.id }/> }
                    </>
                },
                {
                    name: 'Risicoborgingen',
                    id: 'risk-assessments',
                    hasError: assessmentsWithAction.length>0,
                    element: <>
                        { currentCharge.id && <ChargeAssessmentTable
                            parentId={ currentCharge.id }
                            assessmentsWithAction={ assessmentsWithAction }
                        /> }
                    </>
                },
                {
                    name: 'Mutaties',
                    id: 'profit-loss',
                    element: <>
                        { currentCharge.id && <ProfitLossTable parentId={ currentCharge.id }/> }
                    </>
                },
                {
                    name: 'Verkoopomzet per klant',
                    id: 'customers',
                    element: <>
                        { currentCharge.id && <ChargeCustomerTable parentId={ currentCharge.id }/> }
                    </>
                },
                {
                    name: 'Verkooporders',
                    id: 'sales',
                    element: <>
                        { currentCharge.id && <ChargeSaleRowTable parentId={ currentCharge.id }/> }
                    </>
                },
                {
                    name: 'COA',
                    id: 'coa',
                    element: <PdfFile
                        id={ currentCharge.id }
                        fileType={ 'coa' }
                        customFileName={ `'coa-${ currentCharge?.product?.number }-${ batchCode }` }
                    />
                },
                {
                    name: 'Doossticker',
                    id: 'sticker',
                    element: <ChargeStickerTab
                        chargeId={ currentCharge.id }
                        batchCode={currentCharge.batchcode}
                    />
                },
                {
                    name: 'Palletsticker',
                    id: 'pallet-sticker',
                    element: <ChargePalletStickerTab chargeId={ currentCharge.id } />
                },
                {
                  name: 'Kleine sticker',
                  id: 'small-sticker',
                  element: <ChargeSmallStickerTab chargeId={ currentCharge.id } />
              }
            ] }
        />
    );
};
export default ChargeShow;