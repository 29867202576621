import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { UseHttp } from '../../modules/Http/useHttp';
import { ModalState } from './Modal';
import WarningModal from './WarningModal';


type DefaultProps<Model extends object, Dto extends object, RelationBlueprint extends string> = {
    useHttp: UseHttp<Model, Dto, RelationBlueprint>
    id?: number,
    resourceName: ReactNode,
    itemName?: ReactNode,
    onSuccess?: (id?: number) => void,
    onCancel?: (id?: number) => void,
    overwriteArchiveMethod?: undefined
}
type OverWriteProps = {
    useHttp: undefined
    id?: number,
    resourceName: ReactNode,
    itemName?: ReactNode,
    onSuccess?: (id?: number) => void,
    onCancel?: (id?: number) => void,
    overwriteArchiveMethod: (id: number) => Promise<{ id: number; }|undefined>,
}

export type ArchiveModalProps<Model extends object, Dto extends object, RelationBlueprint extends string> = ModalState&
    (DefaultProps<Model, Dto, RelationBlueprint>|OverWriteProps)

const ArchiveModal = <Model extends object, Dto extends object, RelationBlueprint extends string>({
    open,
    setOpen,
    id,
    useHttp,
    resourceName,
    itemName,
    onSuccess,
    onCancel,
    overwriteArchiveMethod
}: ArchiveModalProps<Model, Dto, RelationBlueprint>): JSX.Element => {

    const [ internalOpen, setInternalOpen ] = useState(open);
    useEffect(() => {
        if (open) {
            setInternalOpen(true);
        }
    }, [ open ]);

    useEffect(() => {
        if (!internalOpen) {
            setTimeout(() => {
                setOpen(false);
            }, 350);
        }
    }, [ internalOpen ]);

    const handleClose = () => {
        setInternalOpen(false);
    };

    const onCancelClick = () => {
        onCancel?.(id);
        handleClose();
    };

    const handleConfirm = async() => {
        if (overwriteArchiveMethod) {
            await overwriteArchiveMethod(id!);
        } else {
            await useHttp?.destroy(id!);
        }
        onSuccess?.(id);
        handleClose();
    };

    const handleEscape = useCallback((event: KeyboardEvent) => {
        const { key } = event;
        if (key === 'Escape') {
            onCancelClick();
        }
    }, []);


    useEffect(() => {
        window.addEventListener('keydown', handleEscape);
        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, []);

    return <WarningModal
        confirmCallback={ handleConfirm }
        cancelCallback={ onCancelClick }
        open={ internalOpen }
        setOpen={ setInternalOpen }
        title={ <>{ resourceName } archiveren</> }
        text={ <>
            <p>Weet u zeker dat u het volgende wilt archiveren?</p>
            <p className={ 'font-medium mt-2' }>{ itemName ?? resourceName }</p>
        </> }
    />;
};

export default ArchiveModal;
