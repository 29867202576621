import axios from 'axios';
import useAxios from '../../../../lib/samfe/modules/Http/useAxios';
import {ProductModel} from "../../ProductTypes";
import {SoftDeleteModel} from "../../../../lib/samfe/types/ModelTypes";
import useHttp, { ListResponse } from '../../../../lib/samfe/modules/Http/useHttp';

export type VersionLogRelationsBluePrint = 'product'

export type VersionLogFields = {
    internal_comment: string,
    external_comment: string,
    impact: 'low'|'medium'|'high',
    product_id: number
}

export type VersionLogDto = Partial<VersionLogFields>

export type VersionLogRelations = {
    product: ProductModel,
}

export type VersionLogModel = SoftDeleteModel & Partial<VersionLogRelations> & VersionLogDto
export type ParsedVersionLogModel = VersionLogModel & {version: number}


const useProductVersionLog = () => {

    const { get } = useAxios();

    const productVersionLogHook = useHttp<VersionLogModel, VersionLogDto, VersionLogRelationsBluePrint>({
        endpoint: 'product-versions'
    });

    const getByProductId = async (productId: number): Promise<ParsedVersionLogModel[]> => {
        return await get<ListResponse<ParsedVersionLogModel>>(`products/${ productId }/versions`).then(r => {
            return r.data.items ?? []
        })

    }
    return {
        ...productVersionLogHook,
        getByProductId
    }

}

export const useProductVersionLogByProductId = (productId: number) => useHttp<ParsedVersionLogModel, VersionLogDto, VersionLogRelationsBluePrint>({
    endpoint: `products/${productId}/versions`
});
export default useProductVersionLog;
