import React, { FC, ReactNode, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { SaveButton } from '../../components/button';
import { TableNotification } from '../../components/table/types/Table';
import { dispatchHttpEvent } from '../../events/Http.event';
import TextArea from '../../lib/samfe/components/Form/Generic/TextArea';
import Jumbotron from '../../lib/samfe/components/Jumbotron/Jumbotron';
import Stats from '../../lib/samfe/components/Stats';
import useAsyncMemo from '../../lib/samfe/hooks/useAsyncMemo';
import { datetimeToLocale } from '../../lib/samfe/modules/Parse/Date';
import useId from '../../lib/samfe/modules/Router/useId';
import ShowPage, { FormAction } from '../../templates/pages/ShowPage';
import PurchaseRowForm from './pivot/purchaseRow/PurchaseRowForm';
import PurchaseRowTable from './pivot/purchaseRow/PurchaseRowTable';
import SendPurchaseForm from './pivot/sendPurchase/SendPurchaseForm';
import PurchaseForm from './PurchaseForm';
import { PurchaseModel, PurchaseStatus } from './PurchaseTypes';
import usePurchase from './usePurchase';


const RemarkInlineForm: FC<{ purchaseId: number }> = ({ purchaseId }) => {

    const remarkRef = useRef<HTMLTextAreaElement>(null);
    const purchaseHook = usePurchase();

    const riskSubstantiation = useAsyncMemo(async() => {
        return await purchaseHook.getItem(purchaseId, {
            select: [ 'remarks' ]
        }).then(res => res?.remarks);
    }, [ purchaseId ]);

    return (
        <>
            <TextArea
                className={ '-mt-2' }
                ref={ remarkRef }
                label={ 'Interne notitie' }
                name={ 'remarks' }
                value={ riskSubstantiation }
            />
            <div className={ 'w-full flex justify-end pt-2 mb-4' }>
                <SaveButton
                    className={ '' }
                    disabled={ remarkRef.current == null }
                    onClick={ async() => {
                        await purchaseHook.update(purchaseId, {
                            remarks: remarkRef.current!.value
                        });
                    } }/>
            </div>
        </>
    );
};


const PurchaseShow: FC = (): JSX.Element => {


    const id = useId();
    const [ currentPurchase, setCurrentPurchase ] = useState<PurchaseModel|undefined>(undefined);
    const [ openPurchaseRowCreateForm, setOpenPurchaseRowCreateForm ] = useState(false);


    const baseTitle = useMemo(() => {
        return `Inkooporder ${ currentPurchase?.id ?? '' }`;
    }, [ currentPurchase?.id ]);

    const title = useMemo(() => {
        let message = 'open';
        if (currentPurchase?.status == 'sent') {
            message = 'Verzonden';
        } else if (currentPurchase?.status == 'received') {
            message = 'Ontvangen';
        } else if (currentPurchase?.status == 'booked') {
            message = 'Ingeboekt';
        }
        return <>
            <span>{ baseTitle }</span>
            { message !== '' && <small>&ensp;({ message })</small> }
        </>;
    }, [ currentPurchase?.status, baseTitle ]);


    const noPurchaseRowNotification: TableNotification|undefined = useMemo(() => {
        if ((currentPurchase?.purchaseRows_count ?? 0)>0) {
            return undefined;
        }
        return {
            title: 'Geen inkoopregels aanwezig',
            variation: 'warning'
        } as TableNotification;
    }, [ currentPurchase?.purchaseRows_count ]);


    const orderNote = useMemo(() => {
        if (!currentPurchase?.id) {
            return undefined;
        }
        let commentsField: ReactNode = undefined;
        if ((currentPurchase?.comments ?? '').replaceAll(' ', '') != '') {
            commentsField = <div className={'mb-4'}>
                <Jumbotron title={ 'Inkooporder notitie' } description={ currentPurchase?.comments }/>
            </div>
        }
        return <div className={ 'my-4' }>
            { commentsField }
            <RemarkInlineForm purchaseId={ currentPurchase.id }/>
        </div>;
    }, [ currentPurchase?.comments, currentPurchase?.id ]);


    return (
        <>
            <ShowPage
                title={ title }
                breadcrumb={ baseTitle }
                setCurrentModel={ setCurrentPurchase }
                FormModal={ PurchaseForm }
                httpHook={ usePurchase }
                counts={ [ 'purchaseRows' ] }
                relations={ [ 'producer' ] }
                formActions={ (() => {
                    const formActions: FormAction[] = [];

                    if ((currentPurchase?.purchaseRows_count ?? 0) == 0) {
                        return formActions;
                    }

                    if (!([ 'received', 'booked' ] as PurchaseStatus[]).includes(currentPurchase?.status ?? 'booked')) {
                        formActions.push({
                            name: `Order ${ currentPurchase?.status?.toLowerCase() === 'sent' ?'opnieuw ' :' ' }verzenden`,
                            icon: 'mail',
                            form: SendPurchaseForm
                        });
                    }
                    return formActions;
                })() }
                customButtonActions={ [
                    {
                        title: 'Orderregel toevoegen',
                        hidden: currentPurchase?.status !== 'open',
                        onClick: () => setOpenPurchaseRowCreateForm(true)
                    }
                ] }

                tabs={ [
                    {
                        name: 'Overzicht',
                        id: 'overview',
                        element: <>

                            <Stats stats={ [
                                {
                                    name: 'Leverancier',
                                    value: <Link className={ 'underline underline-offset-2' } to={ `/producers/${ currentPurchase?.producer_id }` }>
                                        { currentPurchase?.producer?.name }
                                    </Link>
                                },
                                {
                                    name: 'Verwachte leverweek',
                                    value: `Week ${ currentPurchase?.week_expected ?? 0 }`
                                },
                                {
                                    name: 'Aanmaakdatum',
                                    value: datetimeToLocale(currentPurchase?.created_at)
                                },
                                {
                                    name: 'Besteldatum',
                                    value: currentPurchase?.status != 'open' ?datetimeToLocale(currentPurchase?.order_date) :'-'
                                }

                            ] }/>
                            { currentPurchase?.id && <PurchaseRowTable
                                parentId={ currentPurchase?.id }
                                notification={ noPurchaseRowNotification }
                                htmlBefore={ orderNote }
                            /> }
                        </>
                    }
                ] }
            />

            { openPurchaseRowCreateForm && <PurchaseRowForm
                open={ openPurchaseRowCreateForm }
                setOpen={ setOpenPurchaseRowCreateForm }
                parentId={ id }
                onSuccess={ dispatchHttpEvent }
            /> }
        </>
    );
};
export default PurchaseShow;