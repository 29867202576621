import { Field } from 'formik';
import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { classNames } from '../../../modules/Parse/String';
import Skeleton from '../../Skeleton/Skeleton';
import ErrorMessage from '../Caption/ErrorMessage';
import Label from '../Caption/Label';
import WarningMessage from '../Caption/WarningMessage';
import useFormikField from '../Effects/useFormikField';
import useInput from '../Effects/useInput';
import { requiredFieldClassname } from '../Support/FieldSupport';


type Props = Omit<JSX.IntrinsicElements['textarea'], 'name'>&{
    label: string;
    name: string;
    defaultValue?: string,
    description?: string,
    handleValueChange?: (value: string|boolean|number) => void,
    warning?: string
}

const TextArea: React.FC<Props> = ({
    label,
    name,
    required,
    className,
    disabled,
    description,
    defaultValue,
    handleValueChange,
    warning,
    ...props
}): JSX.Element => {

    const { field, meta, invalid } = useFormikField(name);
    const [ value, setValue ] = useInput(name, 'text', field.value ?? '');
    useEffect(() => {
        setValue(field.value);
    }, [ field.value ]);

    const hasError = useMemo(() => {
        return meta && meta.touched && meta.error;
    }, [ meta ]);

    const hasWarning = useMemo(() => {
        return (warning ?? '').replaceAll(' ', '') !== '';
    }, [ warning ]);

    return value !== undefined ?<>
        <Label
            hidden={ props.hidden }
            invalid={ invalid }
            htmlFor={ name }
            warning={ hasWarning }
        >
            { label }{ required && '*' }
        </Label>

        { description && <span className={ classNames(
            'text-gray-500 font-normal text-sm',
            hasError && '!text-red-700',
            hasWarning && !hasError && '!text-orange-600'
        ) }>{ description }</span> }

        <div className="mt-2">
            <Field
                { ...props }
                { ...field }
                id={ name }
                value={ value }
                onInput={ (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.currentTarget.value) }
                as={ 'textarea' }
                rows={ 4 }
                data-testid={ field.name }
                required={ required }
                disabled={ disabled }
                onChange={ (e: ChangeEvent<HTMLTextAreaElement>) => handleValueChange && handleValueChange(e.target.value) }
                className={ classNames(
                    'block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6',
                    invalid? 'ring-red-700' : 'ring-gray-300',
                    warning && !invalid && '!ring-orange-600',
                    required && requiredFieldClassname,
                    disabled && 'hover:cursor-not-allowed opacity-60',
                    className
                ) }
            />
            {hasError && <ErrorMessage meta={meta} /> }
            {hasWarning && !hasError && <WarningMessage message={warning} />}
        </div>
    </> :<Skeleton type={ 'textArea' }/>;

};
export default TextArea;
