import React from 'react';
import { TableActionComponentType, TableActionProps } from '../../types/TableAction';
import TableActionButtonComponent from '../actions/TableActionButton.component';
import TableActionInline from '../actions/TableActionInline.component';
import TableActionMenuComponent from '../actions/TableActionMenu.component';


type Props<T extends object> = {
    type: TableActionComponentType,
    items: TableActionProps<T>[]
}

const ActionTdComponent = <T extends object>({
    type,
    items
}: Props<T>) => {

    return (
        <>
            { type == 'menu' && <TableActionMenuComponent items={ items }/> }

            { type == 'buttons' && <div className={'flex flex-col gap-y-2 -mr-1 sm:-mr-6 md:-mr-9'}>
                { items.map(({ children, onClick, disabled, className, style, variation }, i) => <TableActionButtonComponent
                    key={ i }
                    { ...{ children, onClick, disabled, className, style, variation } }
                >
                    { children }
                </TableActionButtonComponent>) }
            </div> }

            { type == 'inline' && <div className={'flex gap-y-2 -mr-1 sm:-mr-6 md:-mr-9 justify-end'}>
                { items.reverse().map(({ children, onClick, disabled, className, style, variation }, i) => <TableActionInline
                    key={ i }
                    { ...{ children, onClick, disabled, className, style, variation } }
                >
                    { children }
                </TableActionInline>) }
            </div> }
        </>
    );
};

export default ActionTdComponent;
